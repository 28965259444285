/* Publication cas - Examens */
body.home #main #actualites .block-actus.cas-examens-container {
  margin-top: 30px;
  width: 100%;
  max-width: unset;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.hide {
  display: none !important;
}

.flright {
  float: right !important;
}

.row .col-2 {
  width: 50%;
  float: left;
  vertical-align: top;
}

.blue-line-separator {
  content: "";
  width: 100%;
  height: 1px;
  background: #77b2e6;
  margin: 10px 0 20px;
}

.cas-examens-container .examens-errors {
  color: #ff5e5e;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
.cas-examens-container .new-examen-modalite-select {
  font-size: 16px;
}
.cas-examens-container .examen-subtitle {
  font-size: 16px;
  padding-left: 40px;
}
.cas-examens-container .examens-container .number-input {
  border: none;
  padding: 0 !important;
  margin: 5px 0 8px;
  clear: both;
  width: 60px;
  outline: none !important;
  text-align: center;
  appearance: none;
}
.cas-examens-container .examens-container .ui-spinner-input {
  outline: none !important;
}
.cas-examens-container .examens-container .ui-widget-header {
  background: none !important;
  border: none !important;
}
.cas-examens-container .examens-container .ui-tabs-vertical {
  width: 100%;
  border: none;
  border-bottom: 2px solid #77b2e6;
  border-radius: 0;
  padding: 0 0 20px 230px;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav {
  width: 230px;
  position: absolute;
  left: 0;
  top: 0;
  border: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 0;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li {
  clear: left;
  width: 100%;
  border: 0;
  border: 1px solid #77b2e6;
  margin: 0;
  border-radius: 0;
  background: #ffffff;
  outline: none;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li a {
  display: block;
  font-weight: bold;
  color: #77b2e6;
  float: none;
  width: 100%;
  padding-left: 10px;
  white-space: initial;
  outline: none;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li a:hover {
  text-decoration: underline;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active {
  background: #77b2e6;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active a {
  color: #ffffff;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active a:hover {
  text-decoration: none;
  cursor: default;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .examen-list-item {
  margin: 8px 0 4px;
  border-right: 0;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .examen-list-item.examen-error {
  border: 0;
  border-left: 8px solid #ff7f7f;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .examen-list-item:first-child {
  margin-top: 0;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .group-list-item {
  border: 0;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .group-list-item.ui-tabs-active {
  background: #ffffff;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .group-list-item.ui-tabs-active a {
  color: #000000;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-nav .group-list-item a {
  padding: 3px 0 3px 25px;
}
.cas-examens-container .examens-container .ui-tabs-vertical .ui-tabs-panel {
  padding: 10px 20px;
  width: 100%;
  background: #f0f5f9;
  position: relative;
  box-sizing: border-box;
}
.cas-examens-container .examens-container .image-groupe-images-infos {
  font-size: 11px;
}
.cas-examens-container .examens-container .examen-modalite {
  display: block;
  width: 100%;
  font-size: 12px;
}
.cas-examens-container .examens-container .examen-tab-content select {
  margin: 4px 0 8px 2px;
  clear: both;
}
.cas-examens-container .examens-container label {
  color: #006ece !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  clear: both;
  width: 100%;
}
.cas-examens-container .examens-container .images-groupes-container .bordered-btn {
  background-color: #ffffff;
  border: 1px solid #77b2e6;
  border-radius: 10px;
  color: #77b2e6;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 800;
  margin: 10px 0;
  padding: 7px 15px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  width: 200px;
  float: right;
}
.cas-examens-container .examens-container .images-groupes-container .bordered-btn:hover {
  background: #77b2e6;
  color: #ffffff;
}
.cas-examens-container .examens-container .images-groupes-container h4 {
  border: none;
  padding: 5px 5px;
  color: #ffffff;
  background-color: #006ece;
  margin: 0 0 5px;
}
.cas-examens-container .examens-container .images-groupes-container h4 .btnDelGroupe {
  color: #ffffff;
  float: right;
  font-size: 1.5em;
  margin: -6px 0 0;
  padding: 0;
}
.cas-examens-container .examens-container .images-groupes-container h4 .btnDelGroupe:hover {
  color: #000000;
}
.cas-examens-container .examens-container .images-groupes-container .btnDelGroupe {
  transition: color 0.25s ease;
}
.cas-examens-container .examens-container .images-groupes-container li {
  background: #f0f5fa;
  padding: 8px;
  margin: 0 0 5px;
  position: relative;
}
.cas-examens-container .examens-container .images-groupes-container li .btnDelImg {
  padding: 0;
  position: absolute;
  top: 5px;
  right: 10px;
  margin-right: 22px;
}
.cas-examens-container .examens-container .images-groupes-container li strong {
  font-weight: 700;
}
.cas-examens-container .examens-container .images-groupes-container h5 {
  border: none;
  padding: 5px 5px;
  color: #ffffff;
  background-color: #77b2e6;
  margin: 0 0 5px;
}
.cas-examens-container .examens-container .images-groupes-container #addImgCas:hover {
  color: #ffffff;
}
.cas-examens-container .examens-container .images-groupes-container .liste_imgs {
  float: none;
  width: 100%;
  margin: 10px 0;
  padding: 0 20px;
  list-style: none;
}
.cas-examens-container .examens-container .images-groupes-container .liste_imgs .liste-img-item {
  min-height: 68px;
}
.cas-examens-container .examens-container .images-groupes-container .liste_imgs .liste-img-item img {
  float: left;
  width: auto;
  height: 68px;
  margin-right: 10px;
  max-width: 100px;
}
.cas-examens-container .examens-container .images-groupes-container .upload-errors-container {
  margin: 10px 0;
  padding: 0 20px;
  width: 100%;
}
.cas-examens-container .examens-container .images-groupes-container .upload-errors-container p {
  color: #ff7f7f;
  font-size: 11px;
  font-style: italic;
}
.cas-examens-container .examens-container .images-groupes-container p {
  margin: 10px 0 !important;
}
.cas-examens-container .examens-container .images-groupes-container .image-groupe-description {
  width: 100%;
  resize: vertical;
}
.cas-examens-container .examens-container .images-groupes-container .btn-change-examen-container {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #006ece;
}
.cas-examens-container .examens-container .images-groupes-container .btn-change-examen-container .btnSelectionExamen {
  float: left;
}
.cas-examens-container .examens-container .images-groupes-container .btn-change-examen-container .btnSubdivideImageGroupe {
  float: right;
}
.cas-examens-container .examens-container .images-groupes-container .btn-change-examen-container .btn-image-groupe-delete {
  float: right;
  color: chocolate !important;
  border: 1px solid chocolate !important;
  margin-bottom: 20px;
}
.cas-examens-container .examens-container .images-groupes-container .btn-change-examen-container .btn-image-groupe-delete:hover {
  background: chocolate !important;
  color: #ffffff !important;
}
.cas-examens-container .examens-container .examen-qcm-container {
  border-top: 2px solid #77b2e6;
  padding-top: 20px;
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block + .qcm-block {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block .qcm-field-label {
  padding: 4px 0;
  width: 105px;
  float: left;
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block .qcm-label-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block .qcm-label-container .qcm-label {
  width: calc(95% - 105px);
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block .qcm-label-container .qcm-label .qcm-label-input {
  width: 100%;
}
.cas-examens-container .examens-container .qcm-list-container .qcm-block .qcm-label-container .qcm-action-container {
  width: 5%;
  padding-left: 5px;
  text-align: right;
}
.cas-examens-container .examens-container .qcm-input-field {
  padding: 6px 8px;
  border: none;
  box-sizing: border-box;
}
.cas-examens-container .examens-container .qcm-reponse-block {
  width: 100%;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-label {
  width: 80%;
  padding-right: 10px;
  float: left;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-label .reponse-title {
  width: 105px;
  float: left;
  padding: 4px 0;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-label .reponse-input {
  width: calc(100% - 105px);
  float: right;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-correcte {
  float: left;
  width: 100px;
  margin-right: 10px;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-correcte .reponse-correcte-checkbox {
  width: 15px;
  float: left;
  margin-top: 2px;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-correcte .reponse-correcte-label {
  padding-left: 10px;
  float: left;
  font-style: italic;
}
.cas-examens-container .examens-container .qcm-reponse-block .reponse-row .qcm-reponse-action-container {
  float: right;
  width: 20px;
  margin-left: 5px;
  text-align: right;
}
.cas-examens-container .examens-container .btnAjouterQcmReponse {
  display: inline-block;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  text-indent: 1px;
  text-align: center;
  width: 22px;
  height: 22px;
  line-height: 20px;
  background: #a8cded;
  border-radius: 50%;
  transition: background-color 0.25s ease;
}
.cas-examens-container .examens-container .btnAjouterQcmReponse:hover {
  cursor: pointer;
  background: #77b2e5;
}
.cas-examens-container .examens-container .btn-delete {
  display: inline-block;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  text-indent: 1px;
  width: 22px;
  height: 22px;
  line-height: 20px;
  background: #ff9999;
  text-align: center;
  border-radius: 50%;
  transition: background-color 0.25s ease;
}
.cas-examens-container .examens-container .btn-delete:hover {
  cursor: pointer;
  background: #ff7f7f;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.4;
  background: #000000;
  top: 0;
  left: 0;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content {
  background: #ffffff;
  position: fixed;
  width: 50%;
  min-width: 320px;
  z-index: 2000;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .update-examen-error {
  color: chocolate;
  font-size: 1.2em;
  margin: 0 0 10px;
  font-weight: bold;
  float: right;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content label {
  cursor: pointer;
  color: #006ece !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  width: 55%;
  float: left;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .update-image-groupe-examen-radio {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  width: 10px;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .create-examen-select {
  float: right;
  font-size: 1.2em;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .image-groupe-examen-select {
  float: right;
  font-size: 1.2em;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .btn {
  float: right;
  margin: 0 10px;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .btn.btnCancelUpdateImageGroupeExamen {
  color: chocolate !important;
  border: 1px solid chocolate !important;
  margin-right: 0;
}
.cas-examens-container .update-all-image-groupe-examen .update-examen-content .btn.btnCancelUpdateImageGroupeExamen:hover {
  background: chocolate !important;
  color: #ffffff !important;
}
.cas-examens-container .update-image-groupe-examen .update-examen-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.4;
  background: #000000;
  top: 0;
  left: 0;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content {
  background: #ffffff;
  position: fixed;
  width: 50%;
  min-width: 320px;
  z-index: 2000;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .update-examen-error {
  color: chocolate;
  font-size: 1.2em;
  margin: 0 0 10px;
  font-weight: bold;
  float: right;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content label {
  cursor: pointer;
  color: #006ece !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  width: 55%;
  float: left;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .update-image-groupe-examen-radio {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  width: 10px;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .create-examen-select {
  float: right;
  font-size: 1.2em;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .image-groupe-examen-select {
  float: right;
  font-size: 1.2em;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .btn {
  float: right;
  margin: 0 10px;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .btn.btnCancelUpdateImageGroupeExamen {
  color: chocolate !important;
  border: 1px solid chocolate !important;
  margin-right: 0;
}
.cas-examens-container .update-image-groupe-examen .update-examen-content .btn.btnCancelUpdateImageGroupeExamen:hover {
  background: chocolate !important;
  color: #ffffff !important;
}
.cas-examens-container .examen-action-delete-container .btn-examen-delete {
  float: right;
  color: chocolate !important;
  border: 1px solid chocolate !important;
  margin-bottom: 20px;
}
.cas-examens-container .examen-action-delete-container .btn-examen-delete:hover {
  background: chocolate !important;
  color: #ffffff !important;
}

.examens-errors-container {
  margin-bottom: 20px;
  border-left: 4px solid #ff7f7f;
  padding: 0 10px;
}
.examens-errors-container .examen-qcm-errors-container {
  width: 100%;
  padding: 10px 0;
}
.examens-errors-container .examen-qcm-errors-container .examen-error-message {
  width: 100%;
  color: #ff7f7f;
  font-weight: 700;
  font-size: 1.2em;
  margin: 5px 0;
}
.examens-errors-container .examen-qcm-errors-container .examen-error-message:first-child {
  margin-top: 0;
}
.examens-errors-container .examen-qcm-errors-container .examen-error-message:last-child {
  margin-bottom: 0;
}

.file-upload-container .fileinput-button .image-groupe-fileupload {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.file-upload-container .progress {
  width: 100%;
  margin-bottom: 10px;
}
.file-upload-container .examen-images-info {
  font-size: 12px;
  font-style: italic;
  color: #77b2e6;
}

.no-slider-info {
  font-size: 1.4em;
  color: chocolate;
  font-weight: 600;
  font-style: italic;
  text-align: center;
}

.clearfix {
  clear: both !important;
}

.cas-quiz-container .examens-list-container {
  border-left: 5px solid #006ece;
  padding: 20px;
  margin: 20px 0;
}
.cas-quiz-container .examens-list-container .examen-container .qcm-title {
  margin: 0 20px 20px;
  color: #000000;
  padding-bottom: 10px;
}
.cas-quiz-container .examens-list-container .examen-container .qcm-title:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 1px;
  background: #231f20;
  left: 0;
  bottom: 0;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container {
  padding: 10px 15px 40px 15px;
  width: 50%;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container:nth-child(odd) {
  border-right: 1px solid #77b2e6;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-question-container {
  padding: 5px;
  margin-bottom: 10px;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-question-container.qcm-question-error {
  border-bottom: 3px solid #ff7f7f;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-question-container .qcm-question-title {
  color: #006ece;
  font-size: 1.6em;
  font-weight: 700;
  text-transform: uppercase;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-question-container .qcm-question {
  font-size: 1.6em;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container {
  padding: 0 5px;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container .qcm-reponses-title {
  color: #006ece;
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container .qcm-reponse-container {
  margin-bottom: 6px;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container .qcm-reponse-container:last-child {
  margin-bottom: 0;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container .qcm-reponse-container .qcm-reponse-label {
  font-size: 1.4em;
  cursor: pointer;
}
.cas-quiz-container .examens-list-container .examen-container .examen-qcm-container .qcm-container .qcm-reponses-list-container .qcm-reponse-container .qcm-reponse-correcte {
  width: 20px;
  float: left;
  margin-top: 2px;
}
.cas-quiz-container .examens-list-container .examen-container .examen-actions-container .examen-next {
  float: right;
}
.cas-quiz-container .examens-list-container .examen-container .examen-actions-container .final-diagnostic {
  float: right;
}
.cas-quiz-container .diagnostics-container .final-diagnostics-actions-container {
  padding: 20px;
}
.cas-quiz-container .diagnostics-container .final-diagnostics-actions-container .btn-submit-diagnostic {
  float: right;
}

.examen-action-in-progress-container .examen-action-in-progress-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.4;
  background: #000000;
  top: 0;
  left: 0;
}
.examen-action-in-progress-container .examen-action-in-progress-content {
  background: #ffffff;
  position: fixed;
  width: 40%;
  z-index: 2000;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
}
.examen-action-in-progress-container .examen-action-in-progress-content .examen-action-in-progress-text {
  color: #006ece;
  font-size: 1.4em;
  width: 100%;
  text-align: center;
}
.examen-action-in-progress-container .examen-action-in-progress-content .examen-action-in-progress-loader {
  display: block;
  margin: 20px auto;
}