$examen_preview_image_height: 68px;
$examen_preview_image_width: 100px;

$light_blue_color: #77b2e6;
$header_blue_color: #006ece;
$background_color: #f0f5f9;
$dark_background_color: #000000;
$red_color: #ff5e5e;
$red_pastel_color: #ff9999;
$red_pastel_hover_color: #ff7f7f;

$blue_pastel_color: #a8cded;
$blue_pastel_hover_color: #77b2e5;

$warning_color: chocolate;