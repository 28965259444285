/* Publication cas - Examens */

body.home {
  #main {
    #actualites {
      .block-actus.cas-examens-container {
        margin-top: 30px;
        width: 100%;
        max-width: unset;
      }
    }
  }
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.hide {
  display: none !important;
}
.flright {
  float: right !important;
}
// .btn {
//   background-color: #ffffff;
//   border-radius: 10px;
//   display: block;
//   float: left;
//   font-size: 12px;
//   font-weight: 800;
//   margin: 0;
//   padding: 7px 15px;
//   text-transform: uppercase;
//   transition: all 0.2s ease 0s;
//   min-width: 200px;
//   color: $light_blue_color;
//   border: 1px solid $light_blue_color;
//   &:hover {
//     background: $light_blue_color;
//     color: #ffffff;
//   }
// }
.row {
  .col-2 {
    width: 50%;
    float: left;
    vertical-align: top;
  }
}
.blue-line-separator {
  content: "";
  width: 100%;
  height: 1px;
  background: $light_blue_color;
  margin: 10px 0 20px;
}
.cas-examens-container {
  .examens-errors {
    color: $red_color;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
  }
  .new-examen-modalite-select {
    font-size: 16px;
  }
  .examen-subtitle {
    font-size: 16px;
    padding-left: 40px;
  }
  .examens-container {
    .number-input {
      border: none;
      padding: 0 !important;
      margin: 5px 0 8px;
      clear: both;
      width: 60px;
      outline: none !important;
      text-align: center;
      appearance: none;
    }
    .ui-spinner-input {
      outline: none !important;
    }
    .ui-widget-header {
      background: none !important;
      border: none !important;
    }
    .ui-tabs-vertical {
      width: 100%;
      border: none;
      border-bottom: 2px solid $light_blue_color;
      border-radius: 0;
      padding: 0 0 20px 230px;
      position: relative;
      margin-bottom: 20px;
      box-sizing: border-box;
      .ui-tabs-nav {
        width: 230px;
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 0;
        li {
          clear: left;
          width: 100%;
          border: 0;
          border: 1px solid $light_blue_color;
          margin: 0;
          border-radius: 0;
          background: #ffffff;
          outline: none;
          a {
            display: block;
            font-weight: bold;
            color: $light_blue_color;
            float: none;
            width: 100%;
            padding-left: 10px;
            white-space: initial;
            outline: none;
          }
          a:hover {
            text-decoration: underline;
          }
          &.ui-tabs-active {
            background: $light_blue_color;
            a {
              color: #ffffff;
              &:hover {
                text-decoration: none;
                cursor: default;
              }
            }
          }
        }
        .examen-list-item {
          margin: 8px 0 4px;
          border-right: 0;
          &.examen-error {
            border: 0;
            border-left: 8px solid $red_pastel_hover_color;
          }
        }
        .examen-list-item:first-child {
          margin-top: 0;
        }
        .group-list-item {
          border: 0;
        }
        .group-list-item {
          &.ui-tabs-active {
            background: #ffffff;
            a {
              color: #000000;
            }
          }
          a {
            padding: 3px 0 3px 25px;
          }
        }
      }
      .ui-tabs-panel {
        padding: 10px 20px;
        width: 100%;
        background: $background_color;
        position: relative;
        box-sizing: border-box;
      }
    }
    .image-groupe-images-infos {
      font-size: 11px;
    }
    .examen-modalite {
      display: block;
      width: 100%;
      font-size: 12px;
    }
    .examen-tab-content {
      select {
        margin: 4px 0 8px 2px;
        clear: both;
      }
    }
    label {
      color: $header_blue_color !important;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      clear: both;
      width: 100%;
    }
    .images-groupes-container {
      .bordered-btn {
        background-color: #ffffff;
        border: 1px solid #77b2e6;
        border-radius: 10px;
        color: #77b2e6;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 800;
        margin: 10px 0;
        padding: 7px 15px;
        text-transform: uppercase;
        transition: all 0.2s ease 0s;
        width: 200px;
        float: right;
        &:hover {
          background: $light_blue_color;
          color: #ffffff;
        }
      }
      h4 {
        border: none;
        padding: 5px 5px;
        color: #ffffff;
        background-color: $header_blue_color;
        margin: 0 0 5px;
        .btnDelGroupe {
          color: #ffffff;
          float: right;
          font-size: 1.5em;
          margin: -6px 0 0;
          padding: 0;
          &:hover {
            color: #000000;
          }
        }
      }
      .btnDelGroupe {
        transition: color 0.25s ease;
      }
      li {
        background: #f0f5fa;
        padding: 8px;
        margin: 0 0 5px;
        position: relative;
        .btnDelImg {
          padding: 0;
          position: absolute;
          top: 5px;
          right: 10px;
          margin-right: 22px;
        }
        strong {
          font-weight: 700;
        }
      }
      h5 {
        border: none;
        padding: 5px 5px;
        color: #ffffff;
        background-color: $light_blue_color;
        margin: 0 0 5px;
      }
      #addImgCas:hover {
        color: #ffffff;
      }
      .liste_imgs {
        float: none;
        width: 100%;
        margin: 10px 0;
        padding: 0 20px;
        list-style: none;
        .liste-img-item {
          min-height: 68px;
          img {
            float: left;
            width: auto;
            height: $examen_preview_image_height;
            margin-right: 10px;
            max-width: $examen_preview_image_width;
          }
        }
      }
      .upload-errors-container {
        margin: 10px 0;
        padding: 0 20px;
        width: 100%;
        p {
          color: $red_pastel_hover_color;
          font-size: 11px;
          font-style: italic;
        }
      }
      p {
        margin: 10px 0 !important;
      }
      .image-groupe-description {
        width: 100%;
        resize: vertical;
      }
      .btn-change-examen-container {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $header_blue_color;
        .btnSelectionExamen {
          float: left;
        }
        .btnSubdivideImageGroupe {
          float: right;
        }
        .btn-image-groupe-delete {
          float: right;
          color: $warning_color !important;
          border: 1px solid $warning_color !important;
          margin-bottom: 20px;
          &:hover {
            background: $warning_color !important;
            color: #ffffff !important;
          }
        }
      }
    }
    .examen-qcm-container {
      border-top: 2px solid #77b2e6;
      padding-top: 20px;
    }
    .qcm-list-container {
      .qcm-block {
        & + .qcm-block {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
        .qcm-field-label {
          padding: 4px 0;
          width: 105px;
          float: left;
        }
        .qcm-label-container {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: space-between;
          .qcm-label {
            width: calc(95% - 105px);
            .qcm-label-input {
              width: 100%;
            }
          }
          .qcm-action-container {
            width: 5%;
            padding-left: 5px;
            text-align: right;
          }
        }
      }
    }
    .qcm-input-field {
      padding: 6px 8px;
      border: none;
      box-sizing: border-box;
    }
    .qcm-reponse-block {
      width: 100%;
      .reponse-row {
        .qcm-reponse-label {
          width: 80%;
          padding-right: 10px;
          float: left;
          .reponse-title {
            width: 105px;
            float: left;
            padding: 4px 0;
          }
          .reponse-input {
            width: calc(100% - 105px);
            float: right;
          }
        }
        .qcm-reponse-correcte {
          float: left;
          width: 100px;
          margin-right: 10px;
          .reponse-correcte-checkbox {
            width: 15px;
            float: left;
            margin-top: 2px;
          }
          .reponse-correcte-label {
            padding-left: 10px;
            float: left;
            font-style: italic;
          }
        }
        .qcm-reponse-action-container {
          float: right;
          width: 20px;
          margin-left: 5px;
          text-align: right;
        }
      }
    }
    .btnAjouterQcmReponse {
      display: inline-block;
      color: #ffffff;
      font-weight: 700;
      font-size: 16px;
      text-indent: 1px;
      text-align: center;
      width: 22px;
      height: 22px;
      line-height: 20px;
      background: $blue_pastel_color;
      border-radius: 50%;
      transition: background-color 0.25s ease;
      &:hover {
        cursor: pointer;
        background: $blue_pastel_hover_color;
      }
    }
    .btn-delete {
      display: inline-block;
      color: #ffffff;
      font-weight: 700;
      font-size: 14px;
      text-indent: 1px;
      width: 22px;
      height: 22px;
      line-height: 20px;
      background: $red_pastel_color;
      text-align: center;
      border-radius: 50%;
      transition: background-color 0.25s ease;
      &:hover {
        cursor: pointer;
        background: $red_pastel_hover_color;
      }
    }
  }
  .update-all-image-groupe-examen {
    .update-examen-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0.4;
      background: $dark_background_color;
      top: 0;
      left: 0;
    }
    .update-examen-content {
      background: #ffffff;
      position: fixed;
      width: 50%;
      min-width: 320px;
      z-index: 2000;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 40px;
      .update-examen-error {
        color: $warning_color;
        font-size: 1.2em;
        margin: 0 0 10px;
        font-weight: bold;
        float: right;
      }
      label {
        cursor: pointer;
        color: #006ece !important;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        width: 55%;
        float: left;
      }
      .update-image-groupe-examen-radio {
        float: left;
        margin-right: 10px;
        margin-top: 5px;
        width: 10px;
      }
      .create-examen-select {
        float: right;
        font-size: 1.2em;
      }
      .image-groupe-examen-select {
        float: right;
        font-size: 1.2em;
      }
      .btn {
        float: right;
        margin: 0 10px;
        &.btnCancelUpdateImageGroupeExamen {
          color: $warning_color !important;
          border: 1px solid $warning_color !important;
          margin-right: 0;
          &:hover {
            background: $warning_color !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
  .update-image-groupe-examen {
    .update-examen-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0.4;
      background: $dark_background_color;
      top: 0;
      left: 0;
    }
    .update-examen-content {
      background: #ffffff;
      position: fixed;
      width: 50%;
      min-width: 320px;
      z-index: 2000;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 40px;
      .update-examen-error {
        color: $warning_color;
        font-size: 1.2em;
        margin: 0 0 10px;
        font-weight: bold;
        float: right;
      }
      label {
        cursor: pointer;
        color: #006ece !important;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        width: 55%;
        float: left;
      }
      .update-image-groupe-examen-radio {
        float: left;
        margin-right: 10px;
        margin-top: 5px;
        width: 10px;
      }
      .create-examen-select {
        float: right;
        font-size: 1.2em;
      }
      .image-groupe-examen-select {
        float: right;
        font-size: 1.2em;
      }
      .btn {
        float: right;
        margin: 0 10px;
        &.btnCancelUpdateImageGroupeExamen {
          color: $warning_color !important;
          border: 1px solid $warning_color !important;
          margin-right: 0;
          &:hover {
            background: $warning_color !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }
  .examen-action-delete-container {
    .btn-examen-delete {
      float: right;
      color: $warning_color !important;
      border: 1px solid $warning_color !important;
      margin-bottom: 20px;
      &:hover {
        background: $warning_color !important;
        color: #ffffff !important;
      }
    }
  }
}
.examens-errors-container {
  margin-bottom: 20px;
  border-left: 4px solid $red_pastel_hover_color;
  padding: 0 10px;
  .examen-qcm-errors-container {
    width: 100%;
    padding: 10px 0;
    .examen-error-message {
      width: 100%;
      color: $red_pastel_hover_color;
      font-weight: 700;
      font-size: 1.2em;
      margin: 5px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.file-upload-container {
  .fileinput-button {
    .image-groupe-fileupload {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .progress {
    width: 100%;
    margin-bottom: 10px;
  }
  .examen-images-info {
    font-size: 12px;
    font-style: italic;
    color: $light_blue_color;
  }
}
.no-slider-info {
  font-size: 1.4em;
  color: $warning_color;
  font-weight: 600;
  font-style: italic;
  text-align: center;
}
.clearfix {
  clear: both !important;
}
.cas-quiz-container {
  .examens-list-container {
    border-left: 5px solid #006ece;
    padding: 20px;
    margin: 20px 0;
    .examen-container {
      .qcm-title {
        margin: 0 20px 20px;
        color: #000000;
        padding-bottom: 10px;
        &:after {
          position: absolute;
          content: "";
          width: 35px;
          height: 1px;
          background: #231f20;
          left: 0;
          bottom: 0;
        }
      }
      .examen-qcm-container {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        .qcm-container {
          padding: 10px 15px 40px 15px;
          // margin-bottom: 20px;
          width: 50%;
          &:nth-child(odd) {
            border-right: 1px solid $light_blue_color;
          }
          .qcm-question-container {
            padding: 5px;
            margin-bottom: 10px;
            &.qcm-question-error {
              border-bottom: 3px solid $red_pastel_hover_color;
            }
            .qcm-question-title {
              color: $header_blue_color;
              font-size: 1.6em;
              font-weight: 700;
              text-transform: uppercase;
            }
            .qcm-question {
              font-size: 1.6em;
            }
          }
          .qcm-reponses-list-container {
            padding: 0 5px;
            .qcm-reponses-title {
              color: $header_blue_color;
              font-size: 1.4em;
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 10px;
            }
            .qcm-reponse-container {
              margin-bottom: 6px;
              &:last-child {
                margin-bottom: 0;
              }
              .qcm-reponse-label {
                font-size: 1.4em;
                cursor: pointer;
              }
              .qcm-reponse-correcte {
                width: 20px;
                float: left;
                margin-top: 2px;
              }
            }
          }
        }
      }
      .examen-actions-container {
        .examen-next {
          float: right;
        }
        .final-diagnostic {
          float: right;
        }
      }
    }
  }
  .diagnostics-container {
    .final-diagnostics-actions-container {
      padding: 20px;
      .btn-submit-diagnostic {
        float: right;
      }
    }
  }
}
.examen-action-in-progress-container {
  .examen-action-in-progress-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.4;
    background: $dark_background_color;
    top: 0;
    left: 0;
  }
  .examen-action-in-progress-content {
    background: #ffffff;
    position: fixed;
    width: 40%;
    z-index: 2000;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 40px;
    .examen-action-in-progress-text {
      color: $header_blue_color;
      font-size: 1.4em;
      width: 100%;
      text-align: center;
    }
    .examen-action-in-progress-loader {
      display: block;
      margin: 20px auto;
    }
  }
}
